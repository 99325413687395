/* Fonts */
@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-ExtraBoldIt.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-MediumIt.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-RegularIt.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-SemiBoldIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-ThinIt.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-BoldIt.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-BookIt.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Cygre';
    src: url('../public/fonts/Cygre-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}