@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./fonts.css');

body {
    font-family: 'Cygre', sans-serif;
    font-weight: 600;
}

.menu-text {
    @apply text-blue-300 menu-space
}

.menu-space {
    @apply px-2 py-1 rounded-md font-semibold
}

/* Button Menu */
.button-menu {
    @apply bg-black menu-text
}

.button-menu:hover {
    @apply bg-gray-700 menu-text
}

/* Button Menu in the path */
.button-menu-path {
    @apply button-menu opacity-50
}

.button-menu-path:hover {
    @apply bg-black menu-text opacity-20
}

/* Logout button */
.button-logout {
    @apply bg-red-600 text-white menu-space
}

.button-logout:hover {
    @apply bg-red-700 text-white
}